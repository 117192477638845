/* About.css */

.about-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.about-section {
  margin-bottom: 30px;
}

.skills-section,
.experience-section,
.education-section,
.interests-section,
.about-section {
  margin-bottom: 30px;
  border-bottom: 2px solid grey;
}

.skills-column ul,
.tools-column ul {
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    width: -webkit-fill-available;
}
.skills-column li,
.tools-column li {
    margin: 2%;
}

h1, h2, h3 {
  color: #333;
}

ul {
  list-style-type: none;
  padding: 0;
}

.experience-item,
.education-item {
  margin-bottom: 20px;
}

@media (max-width: 600px) {
  .about-container {
    padding: 10px;
  }
}