/* Navbar.css */

.navbar {
  background-color: #333;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.border{
  border-left: .2px solid white;
  margin-right: 15px;
}

.navbar-links {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.navbar li {
  margin-right: 15px;
}

.navbar a {
  text-decoration: none;
  color: white;
  font-weight: bold;
}

.right-icon {
  margin-left: auto;
}

.right-icon img {
  width: 20px;
  height: 20px;
}

.dropdown-toggle {
  display: none;
  background: none;
  border: none;
}

.dropdown-toggle:hover {
  cursor: pointer;
}

@media (max-width: 768px) {
  .navbar-links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 2em;
    left: 0;
    width: 100%;
    background-color: #333;
    padding: 10px;
  }

  .navbar-links.show {
    display: flex;
    z-index: 1000;
  }

  .dropdown-toggle {
    display: block;
  }

  .dropdown-toggle img{
    width: 20px;
    height: 20px;
  }

  .border{
    display:none;
  }
}