 circle.highlighted {
    stroke: black;
    stroke-width: 1.5;
}
.circle-milestone-group:hover {
    cursor: pointer;
}
.dashboard-summary {
  padding-left: 10px;
  padding-right: 20px;
  padding-bottom: 20px;
}
.dashboard-summary .summary-paragraph {
    margin-bottom: 0;
}
td, th, text {
    cursor: default;
    user-select: none;
    -webkit-user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    pointer-events: none;
}
.dashboard-svg {
    display: block;
    margin : 0%;
}
.dashboard-heading {
    display: block;
    margin-left: 3%;
}

 .dashboard-heading{
    padding: 1%;
    margin: 0;
 }
#dashboard-table{
    overflow-x: scroll;
}
 #diagram {
    display: flex;
 }

 .dashboard{
    align-items: center;
    background-color: rgb(203 203 203);
 }

.keys-container {
    display: inline-flex;
    margin-top: 3%;
 }

 .circle-key-container {
    display: inline-flex;
    padding-right: 20px;
 }

 .circle-key {
    padding: 10px;
 }

 label.circle-key {
    font-size: 12px;
 }

/* Table Styles */
table {
    display: table;
    border-collapse: collapse;
    width: 100%;
}

td {
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
    font-size: 9px;
    text-align: center;
    background-color: white;
}

th {
    background-color: #f2f2f2;
    color: #333;
    font-weight: bold;
    border-bottom: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    font-size: 12px
}

tr:nth-child(even) {
    background-color: #f9f9f9;
}

@media (max-width: 575.98px) { /* Styles for extra small devices */
    .diagram {
        width: 450px;
        height: 100px;
    }
    th {
        font-size: 5px
    }
    td {
        font-size: 4px
    }
}

@media (min-width: 576px) and (max-width: 767.98px) { /* Styles for small devices */
    th {
        font-size: 6px
    }
    td {
        font-size: 5px
    }
    table{
        width: 100%;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) { /* Styles for medium devices */
    th {
        font-size: 8px
    }
    td {
        font-size: 7px
    }
    .dashboard-heading{
         font-size:25px
    }
    table{
        width: 98%;
        margin-left: 1%;
        margin-right: 1%;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) { /* Styles for large devices */
    .dashboard-heading{
         font-size:25px
    }
    .dashboard-svg{
        padding-left: 1%;
        padding-right: 1%;
        width: 98%;
    }
    table{
            width: 98%;
            margin-left: 1%;
            margin-right: 1%;
    }
    th {
        font-size: 11px
    }
    td {
        font-size: 9px
    }

}

@media (min-width: 1200px) { /* Styles for extra large devices */
    .dashboard-heading{
         font-size:25px
    }
     .dashboard-svg{
        padding-left: 1%;
        padding-right: 1%;
        width: 98%;
    }
    table{
            width: 98%;
            margin-left: 1%;
            margin-right: 1%;
    }
}