/* Default styles */

        .homepage-heading {
            font-size: 28px;
            padding-left: 10px;
        }

        .homepage-updates {
            font-size: 24px;
            padding-left: 20px;
            padding-top: 0px;
        }

        .homepage-update-list {
            list-style-type: disc;
            padding-left: 40px;
            padding-right: 20px;
        }

        /* Responsive styles */
        @media only screen and (max-width: 600px) {
            .homepage-heading {
                font-size: 28px; /* Adjust font size for smaller screens */
            }

            .homepage-updates {
                font-size: 20px; /* Adjust font size for smaller screens */
            }
        }